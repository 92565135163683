@import url(../../fonts/stylesheet.css);

.hero__inner-cont{
  
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  place-content: center;
  /* background: url(../../asset/why-us-pattern1.png); */



}
.image{
  /* background-repeat: cover;  */
  width: 90%; 
  height: auto;

}

.hero__inner-cont__left{
  display: flex;
  /* background: #6b63633e; */
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items:flex-start;
  margin-top: 3rem;
 
 
}

.heading{
  color: #f9ca4f ;
  
}
.buttondiv{ 
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

}

.logo-link a{
  margin: 1rem;
  
}

.logo-link a img{
  width: 2rem;
}

.logo-link a img:hover{
  transform: translateY(-5px);
  transition: var(--transition);
}

.hero-heading{
  font-weight: 900;
  font-size: 48px;
  line-height: 100%;
  width: 90%;
  font-family: 'Logik Wide Semi', var(--font-oswald);
  font-style: normal;

}
.hero-heading span{
  color: #f9ca4f ;
}

.hero__inner-cont__left p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  font-family: var(--font-roboto);
}
.hero__inner-cont__left__btn{
  align-items: flex-start;
  margin-right: 10px;
  margin-bottom: 10px;
}
.hero-btn a{
 color: #1c1c1c;
  font-weight: 700;
  font-family: 'Logik Wide Semi', var(--font-oswald);
 
}


/* NEW HERO */
.hero-main{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.hero-text h1{
  text-align: center;
  font-size: 4rem;
  font-weight: 600;
 max-width: 20ch;
}
.image{
  
  width: 100%;
  height: 100%;
  
 

}

.hero-img{
  background-size: contain;
  background-repeat: no-repeat;

}
.hero-text span{
  color: #f9ca4f ;
}
.ca{
  cursor: pointer;
}



@media screen and (max-width: 1020px) {

  .hero__inner-cont__left{
    justify-content: flex-start;
    align-items: flex-start;
    margin: 2rem 0rem   ;
  }

  .hero__inner-cont__left h1{
  
    font-size:calc( 48px - 50%) ;
    line-height: calc(120% - 20%);
   
 
  }
  .buttondiv{
    display: flex;
    justify-content: center;
   
  }

  .hero__inner-cont__left p {
    font-style: normal;
    /* width: calc(90% - 10%); */
    font-weight: 400;
    font-size: calc(19px - 20%);
    line-height: calc(160% - 20%);

}



  }

@media screen and (max-width: 790px) {
  .hero__inner-cont{
    display: grid;
    grid-template-columns:1fr;
    margin-top: 5rem;
    place-content: center;
  }
  .hero__inner-cont__left p, .hero__inner-cont__left h1{
    text-align: center;
    width: 100%;
  }
 
  .hero__inner-cont__left__btn{
    align-items: center;
    color: #fff;
    
  }

  .hero__inner-cont__left{
 
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .ca{
    text-align: center;
  }
 
}
@media screen and (max-width: 620px) {

    
}