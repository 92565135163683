@import url("./fonts/stylesheet.css");


 *{ margin:0;
  padding:0;
  border:0;
  outline:0;
  list-style:none;
  text-decoration: none;
  box-sizing: border-box;
}



:root{
      /* GENERAL CONTAINER VARIABLES */
      --container-width-lg: 85%;
      --container-width-md: 90%;
      /* BORDER RADIUS VARIABLES */
      --border-radius-1: 1.2rem;
      --border-radius-2: 0.8rem;
      --border-radius-3: 0.5rem;
      --border-radius-4: 50%;
      /* GENERAL TRANSITION VARIABLE */
      --transition: all 600ms  ease-out;
      /* Fonts */
     --font-oswald: Oswald, sans-serif;
      --font-roboto:   Roboto Mono, monospace;
      --font-color:#E2EAFF;
  
  }
  html{
    scroll-behavior: smooth;
}

body{

  line-height:1.6;
  font-size: 0.9rem;
  /* background: rgb(128,206,245);
  background: linear-gradient(180deg, rgba(128,206,245,1) 39%, rgba(255,255,255,1) 87%); */
  
  /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1001%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='rgba(10%2c 25%2c 23%2c 1)'%3e%3c/rect%3e%3cpath d='M1440 0L1153.77 0L1440 201.56z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M1153.77 0L1440 201.56L1440 296.8L1036.8799999999999 0z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M1036.88 0L1440 296.8L1440 340.36L909.9600000000002 0z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M909.96 0L1440 340.36L1440 418.03000000000003L751.62 0z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M0 560L125.63 560L0 379.36z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M0 379.36L125.63 560L663.53 560L0 299.93z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M0 299.93L663.53 560L1089.84 560L0 186.5z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M0 186.5L1089.84 560L1272.3899999999999 560L0 183.94z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1001'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
     */
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1001%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='rgba(12%2c 53%2c 158%2c 1)'%3e%3c/rect%3e%3cpath d='M1440 0L1153.77 0L1440 201.56z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M1153.77 0L1440 201.56L1440 296.8L1036.8799999999999 0z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M1036.88 0L1440 296.8L1440 340.36L909.9600000000002 0z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M909.96 0L1440 340.36L1440 418.03000000000003L751.62 0z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M0 560L125.63 560L0 379.36z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M0 379.36L125.63 560L663.53 560L0 299.93z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M0 299.93L663.53 560L1089.84 560L0 186.5z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M0 186.5L1089.84 560L1272.3899999999999 560L0 183.94z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1001'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");

  background-size: 100% 100%;
   font-family: 'Roboto Mono', monospace;
  color: #E2EAFF;
}


/* ALL CONTAINER CLASS */
.container{
  width: var(--container-width-lg);
  margin-inline: auto;
  max-width: 1920px;  
}

/* ALL SECTION STYLES */
section{
  padding:20rem 0;
 
}

/* bg-pattern */



a{
  color: #191818;
}
.btn{
  padding:10px 22px ;
  font-weight: 500;
  text-align: center;
  background: #f9ca4f ;
  border-radius: 6px;
}
.btn-trans{
  padding:10px 22px ;
  font-weight: 500;
  text-align: center;
  border-radius: 6px;
}

.btn:hover{
  transition: var(--transition);
  transform: translateY(-1px);
  background: #f1bc35;
}
.btn-trans:hover{
  transition: var(--transition);
  transform: translateY(1px);
  


  }

