@import url(../../fonts/stylesheet.css);
.abtt-container{
    background:  #1e44a5 ;
    border-radius: 1.5rem;
   
  margin: 5rem 1rem;
  padding: 7rem 0;
  
}




.abtt-inner{
    
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  
    place-content: center;
    background: url(../../asset/why-us-pattern1.png);
  
    
  
  
  }
  
  .right-text{
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

  }
  .right-text h2{
    font-weight: 900;
    font-size: 25px;
    line-height: 100%;
    width: 90%;
    font-family: 'Logik Wide Semi', var(--font-oswald);
    font-style: normal;
  }
  .right-text h2 span{
    color:  #f9ca4f ;
    font-family: 'Logik Wide Bold Oblique';
  }
  .right-text__heading{
    color:   #f9ca4f ;
    font-size: 18px;
    font-family: var(--font-roboto);
    letter-spacing: 2px;
    font-weight: bold;
  }
  .buttondiv{ 
    display: flex;
    gap: 1rem;
  
  }

  .left-text p {


    font-style: normal;
   
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #E2EAFF;

  }

  
  
  @media screen and (max-width: 1020px) {
  
    .hero__inner-cont__left{
      justify-content: flex-start;
      align-items: flex-start;
   
    }
  
    .hero__inner-cont__left h1{
    
      font-size:calc( 48px - 30%) ;
      line-height: calc(120% - 20%);
     
      width: calc(90 - 20%)
    }
    .buttondiv{
      display: flex;
      justify-content: center;
     
    }
  
    .hero__inner-cont__left p {
      font-style: normal;
      width: calc(90% - 10%);
      font-weight: 400;
      font-size: calc(19px - 20%);
      line-height: calc(160% - 20%);
  
  }
  
  
  
    }
  
  @media screen and (max-width: 790px) {
    .abtt-inner{
      display: grid;
      grid-template-columns:1fr;
      /* margin: 5rem 0rem ; */
      place-content: center;
    }
    .hero__inner-cont__left p, .hero__inner-cont__left h1{
      text-align: center;
      width: 100%;
    }
   
    .hero__inner-cont__left__btn{
      align-items: center;
      color: #fff;
      
    }
  
    .hero__inner-cont__left{
   
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
   
  }
  @media screen and (max-width: 620px) {
  
      
  }